/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Card } from "@rewards-web/shared/components/card";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

import { SurveyAverageScore } from "../average-score";

export interface SurveyAgencyAverageCardProps {
  averageScore: number;
  previousScore?: number;
}

export function SurveyAgencyAverageCard({
  averageScore,
  previousScore,
}: SurveyAgencyAverageCardProps) {
  return (
    <Card
      css={(theme: AppTheme) =>
        css`
          padding: ${theme.spacing(3)};
          flex-grow: 1;
        `
      }
    >
      <div
        css={(theme: AppTheme) =>
          css`
            display: flex;
            margin-bottom: ${theme.spacing(2)};
            align-items: center;
            gap: ${theme.spacing(1)};
          `
        }
      >
        <Typography variant="h3" fontWeight={700}>
          Agency average
        </Typography>
        <Tooltip title="The overall satisfaction level of employees, based on their responses to all survey questions excluding NPS.">
          <FontAwesomeIcon
            icon={faInfoCircle}
            css={css`
              vertical-align: middle;
            `}
          />
        </Tooltip>
      </div>
      <SurveyAverageScore
        averageScore={averageScore}
        previousScore={previousScore}
        maxScore={5}
      />
    </Card>
  );
}
