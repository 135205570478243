import { ClassNames } from "@emotion/react";
import { faTicket } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, List, ListItem } from "@material-ui/core";
import { useMatch, useNavigate } from "react-router-dom";

import { Divider } from "@rewards-web/shared/components/divider";
import { Typography } from "@rewards-web/shared/components/typography";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";

import {
  AdminPageName,
  ADMIN_PAGE_BY_PATH_NAME,
  ADMIN_SIDE_MENU_PAGE_ORDER,
  ADMIN_PAGE_PATH_BY_NAME,
  ADMIN_PERMISSION_KEY_BY_PAGE,
  AdminSideMenuPageName,
} from "../../../constants/admin-page";
import { useHasPermissionsQuery } from "../../../modules/permissions/hooks/use-has-permissions-query";
import { usePermissionsQuery } from "../../../modules/permissions/hooks/use-permissions-query";
import { AdminPermissionType } from "../../../modules/permissions/types";
import AdminLogo from "./admins-logo";
import CandidateLogo from "./candidate-logo";
import EmployeesLogo from "./employees-logo";
import HomeLogo from "./home-logo";
import JobsLogo from "./jobs-logo";
import PaymentLogo from "./payment-logo";
import RecognitionPointsLogo from "./recognition-points-logo";
import { SettingsLogo } from "./settings-logo";
import { useSideMenuDataQuery } from "./side-menu-data.generated";
import { useSideMenuIdentityDataQuery } from "./side-menu-identity.generated";
import SurveysLogo from "./surveys-logo";

const LOGO_BY_PAGE_NAME: {
  [key in AdminSideMenuPageName]: (props: {
    className: string;
    color?: string;
  }) => JSX.Element;
} = {
  [AdminPageName.home]: HomeLogo,
  [AdminPageName.candidates]: CandidateLogo,
  [AdminPageName.redemptions]: PaymentLogo,
  [AdminPageName.recognition]: RecognitionPointsLogo,
  [AdminPageName.employees]: EmployeesLogo,
  [AdminPageName.jobs]: JobsLogo,
  [AdminPageName.admins]: AdminLogo,
  [AdminPageName.settings]: SettingsLogo,
  [AdminPageName.draw]: ({
    className,
    color,
  }: {
    className: string;
    color?: string;
  }) => <FontAwesomeIcon icon={faTicket} className={className} color={color} />,
  [AdminPageName.surveys]: SurveysLogo,
};

type SideMenuPermissionKey = Exclude<
  AdminPermissionType,
  | "onboarding"
  | "settingsEmployeeAccounts"
  | "settingsCommunication"
  | "settingsRecognition"
  | "settingsRedemption"
  | "settingsJobPosting"
>;

interface SideMenuProps {
  navbarHeight: number;
  navbarWidth: number;
}

export function SideMenu({
  navbarHeight,
  navbarWidth,
}: SideMenuProps): JSX.Element | null {
  const navigate = useNavigate();
  const permissionsQuery = usePermissionsQuery();
  const hasPermissionsQuery = useHasPermissionsQuery<
    Record<SideMenuPermissionKey, "read">
  >({
    home: "read",
    jobs: "read",
    candidates: "read",
    redemptions: "read",
    recognitionPoints: "read",
    employees: "read",
    administrators: "read",
    settings: "read",
    draw: "read",
    surveys: "read",
  });

  const identityQuery = useSideMenuIdentityDataQuery({
    onError: reportError,
  });

  const query = useSideMenuDataQuery({
    onError: reportError,
    skip: !identityQuery.data,
    variables: {
      canEditRecognition:
        hasPermissionsQuery.permissionResults?.recognitionPoints !== true,
      canViewSurveys: hasPermissionsQuery.permissionResults?.surveys === true,
    },
  });

  const surveysPageEnabled = useFeatureFlag(
    "admin-app-surveys-page-enabled-temp"
  );

  const currentPageMatch = useMatch("/:page/*");
  const currentPage = currentPageMatch
    ? ADMIN_PAGE_BY_PATH_NAME[currentPageMatch.pathnameBase]
    : undefined;

  if (
    !identityQuery.data ||
    !hasPermissionsQuery.permissionResults ||
    hasPermissionsQuery.error ||
    !permissionsQuery.data ||
    permissionsQuery.error
  ) {
    // TODO improve error state
    return null;
  }

  const menuItems = ADMIN_SIDE_MENU_PAGE_ORDER.map((section) =>
    section
      .filter((page) => {
        if (
          page === AdminPageName.draw &&
          !query.data?.getMyRewardsOrganization.drawsEnabled
        ) {
          // disable draws option if feature flag not enabled (temp)
          return false;
        }

        return true;
      })
      .filter(
        (page) =>
          // filter pages that the user has no view permissions for
          hasPermissionsQuery.permissionResults![
            ADMIN_PERMISSION_KEY_BY_PAGE[page] as SideMenuPermissionKey
          ]
      )
      .filter((page) => {
        if (
          page === AdminPageName.surveys &&
          (!query.data?.getSurveyEngagement?.numSent || !surveysPageEnabled)
        ) {
          // disable survey option if no surveys have been sent,
          // or feature flag is disabled (temp)
          return false;
        }

        return true;
      })
      .map((page) => ({
        text: page,
        path: ADMIN_PAGE_PATH_BY_NAME[page],
        iconComponent: LOGO_BY_PAGE_NAME[page],
        selected: currentPage === page,
        badge:
          page === AdminPageName.recognition
            ? (query.data?.scheduledRecognitionPointsWithNoMessage?.total ??
                0) > 0
            : undefined,
      }))
  ).filter((section) => section.length > 0);

  if (menuItems.length === 0) {
    throw new Error(`Invariant error: there are no menu items`);
  }

  return (
    <ClassNames>
      {({ css, theme }) => (
        <nav aria-label="side menu">
          <Drawer
            variant="permanent"
            classes={{
              paper: css`
                width: ${navbarWidth}px;
                top: ${navbarHeight}px;
                height: calc(100% - ${navbarHeight}px);
              `,
            }}
          >
            <List
              classes={{
                root: css`
                  margin-top: ${theme.spacing(4)};
                `,
              }}
            >
              {menuItems.map((section, index) => {
                return (
                  <>
                    {section.map((item) => {
                      return (
                        <ListItem
                          classes={{
                            root: css`
                              padding-top: ${theme.spacing(2)};
                              padding-bottom: ${theme.spacing(2)};
                            `,
                          }}
                          alignItems="center"
                          button
                          key={item.text}
                          onClick={() => navigate(item.path)}
                        >
                          {item.iconComponent && (
                            <item.iconComponent
                              className={css`
                                width: 20px;
                                height: 20px;
                                margin-right: ${theme.spacing(2)};
                                margin-left: ${theme.spacing(2)};
                              `}
                              color={
                                item.selected
                                  ? theme.palette.text.primary
                                  : "#7F8E9F"
                              }
                            />
                          )}

                          <Typography
                            variant="body"
                            color={
                              item.selected ? "textPrimary" : "textSecondary"
                            }
                          >
                            {item.text}
                            {item.badge && (
                              <sup
                                className={css`
                                  margin-left: 4px;
                                  vertical-align: top;
                                  position: relative;
                                  top: -5px;
                                `}
                              >
                                <div
                                  className={css`
                                    content: "";
                                    display: inline-block;
                                    background-color: ${theme.palette.secondary
                                      .main};
                                    width: 6px;
                                    height: 6px;
                                    border-radius: 3px;
                                  `}
                                />
                              </sup>
                            )}
                          </Typography>
                        </ListItem>
                      );
                    })}
                    {menuItems.length !== index + 1 && (
                      <Divider
                        className={css`
                          margin: ${theme.spacing(2)};
                          margin-right: ${theme.spacing(3)};
                          margin-left: ${theme.spacing(3)};
                        `}
                      />
                    )}
                  </>
                );
              })}
            </List>
          </Drawer>
        </nav>
      )}
    </ClassNames>
  );
}
