import { invert } from "lodash";

import { AdminPermissionType } from "../modules/permissions/types";

export enum AdminPageName {
  home = "Home",
  candidates = "Candidates",
  redemptions = "Redemptions",
  recognition = "Recognition",
  employees = "Employees",
  jobs = "Jobs",
  admins = "Admins",
  settings = "Settings",
  onboarding = "Onboarding",
  draw = "Draw",
  surveys = "Surveys",
}

export type AdminSideMenuPageName = Exclude<
  AdminPageName,
  AdminPageName.onboarding
>;

export const ADMIN_SIDE_MENU_PAGE_ORDER: AdminSideMenuPageName[][] = [
  [AdminPageName.home, AdminPageName.candidates, AdminPageName.jobs],
  [
    AdminPageName.recognition,
    AdminPageName.employees,
    AdminPageName.redemptions,
    AdminPageName.surveys,
  ],
  [AdminPageName.admins, AdminPageName.draw, AdminPageName.settings],
];

export const ADMIN_PAGE_PATH_BY_NAME: { [key in AdminPageName]: string } = {
  [AdminPageName.home]: "/home",
  [AdminPageName.candidates]: "/candidates",
  [AdminPageName.redemptions]: "/redemptions",
  [AdminPageName.recognition]: "/recognition",
  [AdminPageName.employees]: "/employees",
  [AdminPageName.jobs]: "/jobs",
  [AdminPageName.admins]: "/admins",
  [AdminPageName.settings]: "/settings",
  [AdminPageName.onboarding]: "/onboarding",
  [AdminPageName.draw]: "/draw",
  [AdminPageName.surveys]: "/surveys",
};

export const ADMIN_PAGE_BY_PATH_NAME = invert(ADMIN_PAGE_PATH_BY_NAME) as {
  [key: string]: AdminPageName;
};

export const ADMIN_PERMISSION_KEY_BY_PAGE: {
  [key in AdminPageName]: AdminPermissionType;
} = {
  [AdminPageName.home]: "candidates",
  [AdminPageName.candidates]: "candidates",
  [AdminPageName.redemptions]: "redemptions",
  [AdminPageName.recognition]: "recognitionPoints",
  [AdminPageName.employees]: "employees",
  [AdminPageName.jobs]: "jobs",
  [AdminPageName.admins]: "administrators",
  [AdminPageName.settings]: "settings",
  [AdminPageName.onboarding]: "onboarding",
  [AdminPageName.draw]: "draw",
  [AdminPageName.surveys]: "surveys",
};

export const ADMIN_PERMISSION_PATH_BY_KEY: {
  [key in AdminPermissionType]: string;
} = {
  home: "/home",
  candidates: "/candidates",
  redemptions: "/redemptions",
  recognitionPoints: "/recognition",
  employees: "/employees",
  jobs: "/jobs",
  administrators: "/admins",
  settings: "/settings",
  settingsEmployeeAccounts: "/settings/employee-accounts",
  settingsCommunication: "/settings/communication",
  settingsRecognition: "/settings/recognition",
  settingsJobPosting: "/settings/job-posting",
  settingsRedemption: "/settings/redemption",
  onboarding: "/onboarding",
  draw: "/draw",
  surveys: "/surveys",
};

export const ADMIN_PERMISSION_KEY_BY_PATH = invert(
  ADMIN_PERMISSION_PATH_BY_KEY
) as {
  [key: string]: AdminPermissionType;
};
