/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { faMessageLines } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { last } from "lodash";

import { Button } from "@rewards-web/shared/components/button";
import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import {
  SurveyInsightsDateRangeInput,
  SurveyQuestionType,
} from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import { AppTheme } from "@rewards-web/shared/style/types";

import { SurveyAverageScore } from "../../components/average-score";
import { QuestionCategory } from "../../components/question-category";
import { QuestionText } from "../../components/question-text";
import { SurveyQuestionTypeSupportedByUI } from "../../constants";
import { convertDateRangeToValue, shortenDateRangeLabel } from "../../utils";
import { usePulseSurveyQuestionScoreCardDataQuery } from "./pulse-survey-question-score-card-data.generated";
import { SurveyQuestionScoreComparison } from "./survey-question-score-comparison";

export interface SurveyQuestionScoreCardProps {
  questionType: SurveyQuestionTypeSupportedByUI;
  dateRangeInput?: SurveyInsightsDateRangeInput;
}

export function SurveyQuestionScoreCard({
  questionType,
  dateRangeInput,
}: SurveyQuestionScoreCardProps) {
  const { data, error } = usePulseSurveyQuestionScoreCardDataQuery({
    variables: {
      questionType,
      dateRange: dateRangeInput,
    },
    onError: reportError,
  });

  const theme = useTheme();

  if (error) {
    return null;
  }

  const averageScore = (() => {
    if (!data) return undefined;

    // round to 1 decimal place
    return Math.round(data.getSurveyQuestionResults.averageScore * 10) / 10;
  })();

  const previousScores = data?.getSurveyQuestionResults.previousAverageScores
    ?.map((result) => {
      return {
        averageScore: result.averageScore,
        label: shortenDateRangeLabel(result.dateRange.label),
        year: result.dateRange.year.toString(),
      };
    })
    .reverse(); // order from oldest to newest

  return (
    <Card
      variant="outlined"
      css={(theme: AppTheme) =>
        css`
          padding: ${theme.spacing(2)};
          flex-grow: 1;
          box-sizing: border-box;
          // ensure 3 cards per row, at the most
          width: calc((100% - (${theme.spacing(3)} * 2)) / 3);
          min-width: 300px;
        `
      }
    >
      <QuestionCategory questionType={questionType} />
      <QuestionText questionType={questionType} />
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          margin: ${theme.spacing(1)} 0;
        `}
      >
        <SurveyAverageScore
          averageScore={averageScore}
          previousScore={
            previousScores?.length
              ? last(previousScores)!.averageScore
              : undefined
          }
          maxScore={questionType === SurveyQuestionType.Nps ? 10 : 5}
        />
        {previousScores?.length && (
          <SurveyQuestionScoreComparison
            previousScores={previousScores}
            maxScore={questionType === SurveyQuestionType.Nps ? 10 : 5}
          />
        )}
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.spacing(1)};
        `}
      >
        <Button
          variant="outlined"
          size="small"
          width="auto"
          linkTo={`${questionType.toLowerCase()}?dateRange=${convertDateRangeToValue(
            dateRangeInput
          )}`}
          label="View Details"
        />
        {questionType !== SurveyQuestionType.Nps && (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(0.5)};
            `}
          >
            <FontAwesomeIcon
              icon={faMessageLines}
              color={theme.palette.grey[800]}
            />
            <Typography variant="body" color={theme.palette.grey[800]}>
              {data?.getSurveyQuestionResults.numComments ?? "-"}
            </Typography>
          </div>
        )}
      </div>
    </Card>
  );
}
