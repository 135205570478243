/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { compact } from "lodash";
import { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";

import { Button } from "@rewards-web/shared/components/button";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";

import {
  AdminPageName,
  ADMIN_PAGE_BY_PATH_NAME,
  ADMIN_PERMISSION_KEY_BY_PAGE,
} from "../../../constants/admin-page";
import { useOrganizationLaunchStatusQuery } from "../../../modules/launch/hooks/use-organization-launch-status/organization-launch-status.generated";
import { useHasPermissionQuery } from "../../../modules/permissions/hooks/use-has-permission-query";
import RightArrow from "../navigation-bar/onboarding-progress/right-arrow";
import { Banner } from "./banner";
import { usePageBannersDataQuery } from "./page-banners-data.generated";
import { RequestFullAccessModal } from "./request-full-access-modal";

const REFERRAL_PAGES = new Set<AdminPageName>([
  AdminPageName.candidates,
  AdminPageName.jobs,
]);

const DISABLED_PAGES_DURING_PRELAUNCH = new Set<AdminPageName>([
  AdminPageName.candidates,
  AdminPageName.recognition,
]);

export interface PageBannersProps {
  maxContentWidth?: number;
}

export function PageBanners({
  maxContentWidth,
}: PageBannersProps): JSX.Element | null {
  const theme = useTheme();
  const [requestAccessModalOpen, setRequestAccessModalOpen] = useState(false);
  const track = useTrack();

  const currentPageMatch = useMatch("/:page/*");
  const currentPage = ADMIN_PAGE_BY_PATH_NAME[currentPageMatch?.pathnameBase!];

  const adminPermissionKey = ADMIN_PERMISSION_KEY_BY_PAGE[currentPage];

  const hasFullAccessPermission = useHasPermissionQuery(
    "full",
    adminPermissionKey
  );

  const pageBannersData = usePageBannersDataQuery({
    onError: reportError,
    fetchPolicy: "cache-and-network",
  });

  const isReadOnly = (() => {
    if (currentPage === AdminPageName.surveys) {
      // do not show read-only banner on surveys page,
      // since there is no corresponding edit permission
      return false;
    }

    return (
      hasFullAccessPermission.hasPermission === false ||
      // show a read only banner on the recognition page
      // if they can only send recognition for categories with default amount,
      // but there are none
      (currentPage === AdminPageName.recognition &&
        pageBannersData.data?.myAdmin?.permissions.__typename ===
          "RewardsAdminRestrictedAccessPermissions" &&
        pageBannersData.data?.myAdmin?.permissions.permissionsV2
          ?.recognitionPoints?.canOverrideRecognitionCategoryDefaultAmounts ===
          false &&
        pageBannersData.data.recognitionCategoriesWithDefaultAmount.length ===
          0)
    );
  })();

  useEffect(() => {
    if (isReadOnly) {
      track("Viewed read-only page banner", { page: currentPage });
    }
  }, [track, isReadOnly, currentPage]);

  const handleRequestAccess = () => {
    setRequestAccessModalOpen(true);
    track("User pressed request access button on read only banner", {
      page: currentPage,
    });
  };

  const referralsDisabledBannerShowing =
    pageBannersData.data?.getMyRewardsOrganization.referralsEnabled === false &&
    REFERRAL_PAGES.has(currentPage);

  useEffect(() => {
    if (referralsDisabledBannerShowing) {
      track("Viewed referrals disabled page banner", { currentPage });
    }
  }, [track, referralsDisabledBannerShowing, currentPage]);

  const organizationLaunched = useOrganizationLaunchStatusQuery({
    onError: reportError,
    fetchPolicy: "cache-first",
  });

  const isPrelaunchState =
    organizationLaunched.data?.getMyRewardsOrganization.launched === false;

  const prelaunchBannerVisible =
    isPrelaunchState &&
    DISABLED_PAGES_DURING_PRELAUNCH.has(currentPage) &&
    !referralsDisabledBannerShowing;

  useEffect(() => {
    if (prelaunchBannerVisible) {
      track("Viewed pre-launch page banner", { currentPage });
    }
  }, [track, prelaunchBannerVisible, currentPage]);

  const multipleBannersShowing =
    compact([
      prelaunchBannerVisible,
      isReadOnly,
      referralsDisabledBannerShowing,
    ]).length > 1;

  if (organizationLaunched.loading || pageBannersData.loading) {
    return null;
  }

  return (
    <div data-testid="page-banners">
      <RequestFullAccessModal
        open={requestAccessModalOpen}
        onClose={() => {
          setRequestAccessModalOpen(false);
        }}
      />

      <Banner
        testId="referrals-disabled-banner"
        maxWidth={maxContentWidth}
        hidden={!referralsDisabledBannerShowing}
        title="Referrals"
        description={
          <>
            This page is not available because job referrals are disabled.
            Please contact{" "}
            <a href="mailto:help@caribou.care">help@caribou.care</a> if you
            would like to enable referrals.
          </>
        }
        withTitleIcon={multipleBannersShowing}
      />

      <Banner
        testId="pre-launch-banner"
        maxWidth={maxContentWidth}
        hidden={!prelaunchBannerVisible}
        title="Launch"
        description={
          currentPage === AdminPageName.candidates
            ? "You can't create candidates because you have not launched yet."
            : "You can't use this page because you have not launched yet."
        }
        withTitleIcon={multipleBannersShowing}
        action={
          hasFullAccessPermission && (
            <Button
              css={css`
                justify-content: space-between;
              `}
              minWidthPx={120}
              color="tertiary"
              endIcon={<RightArrow color={theme.palette.text.primary} />}
              linkTo="/onboarding"
              label="Complete Onboarding"
              size="small"
              width="auto"
            />
          )
        }
      />

      <Banner
        testId="read-only-banner"
        maxWidth={maxContentWidth}
        hidden={!isReadOnly}
        title="Permission"
        description="Your account has View-Only access for this page."
        withTitleIcon={multipleBannersShowing}
        action={
          <Button
            color="tertiary"
            label="Request Access"
            width="auto"
            size="small"
            onClick={handleRequestAccess}
          />
        }
      />
    </div>
  );
}
